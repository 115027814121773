import BasePlugin from '../BasePlugin'
import MatchingPlanningPrerequisitesCmp from './MatchingPlanningPrerequisitesCmp';

export default class ChangingPrerequisitesPlanningStatus extends BasePlugin {
  async execute() {
    const h = this.context.$createElement
    let values = this.context.getModel()
    let statuses = [ 7, 6, 12, 11, 8, 13, 14, 4 ]
    let message = 'Группа предпосылок планирования уже отправлена на согласование!'
    if (this.context.getModel().attr_1565_ === 5) {
      this.context.$msgbox({
        type: 'info',
        message: message
      })
      done()
    } else if (statuses.includes(this.context.getModel().attr_1565_)) {
      let message = 'Группа предпосылок планирования уже согласована!'
      this.context.$msgbox({
        type: 'info',
        message: message
      })
      done()
    } else {
      this.context.$msgbox({
        title: 'Согласование групп предпосылок планирования',
        message: h('p', {key: Math.random()}, [
          h(MatchingPlanningPrerequisitesCmp, {props: {model: values}})
        ]),
        showCancelButton: true,
        confirmButtonText: 'Да',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            let data = {
              'planningPrerequisiteId': this.context.getModel().id
            }
            this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/ChangingPrerequisitesPlanningStatus`,
              data,
              {
                headers: {
                  'Content-Type': 'application/json'
                }
              }
            )
            done()
          } else {
            done()
          }
        }
      })
    }
  }
}
